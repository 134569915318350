import { Controller } from "@hotwired/stimulus";
import intlTelInput from "intl-tel-input/build/js/intlTelInputWithUtils.min.js";

export default class extends Controller {
  static targets = ["input"];

  static values = {
    currentNumber: String,
    hiddenInputName: String,
  };

  connect() {
    this.intlInput = intlTelInput(this.inputTarget, {
      hiddenInput: () => ({ phone: this.hiddenInputNameValue }),
      nationalMode: true,
      placeholderNumberType: "MOBILE",
      separateDialCode: true,
      strictMode: true,
      countryOrder: ["tr"],
      preferredCountries: ["tr"],
      initialCountry: "tr",
      // geoIpLookup: (callback) => {
      //   fetch("https://ipapi.co/json")
      //     .then((res) => res.json())
      //     .then((data) => callback(data.country_code))
      //     .catch(() => callback("tr"));
      // },
    });

    if (this.currentNumberValue !== "") {
      this.intlInput.setNumber(this.currentNumberValue);
    }
  }

  disconnect() {
    this.intlInput.destroy();
    super.disconnect();
  }
}

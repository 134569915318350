import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["phoneInput", "emailInput"];

  static values = {
    currentType: String,
  };

  connect() {
    if (this.currentTypeValue === "phone_number") {
      this.phoneInputTarget.checked = true;
      this.phoneInputTarget.click();
    } else {
      this.emailInputTarget.checked = true;
      this.emailInputTarget.click();
    }

    this.#setInputsVisiblity();
  }

  select(event) {
    this.currentTypeValue = event.target.value;
    this.#setInputsVisiblity();
  }

  #setInputsVisiblity() {
    if (this.currentTypeValue === "phone_number") {
      this.#updateElementVisibility(this.phoneInputTarget, true);
      this.#updateElementVisibility(this.emailInputTarget, false);
    } else {
      this.#updateElementVisibility(this.phoneInputTarget, false);
      this.#updateElementVisibility(this.emailInputTarget, true);
    }
  }

  #updateElementVisibility(element, visible) {
    const elements = [element, element.closest(".form-group")];

    for (const el of elements) {
      el.classList[visible ? "remove" : "add"]("d-none");
      el.disabled = !visible;
    }
  }
}
